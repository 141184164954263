<template>
  <div>
    <MyTable
      :columns="columns"
      :rows="orders"
      :is-loading="isLoading"
    >
      <template v-slot="{props}">
        <span v-if="props.column.field === 'delivery.id'">
          <b-button
            variant="primary"
            class="p-1 text-white font-weight-bolder"
          >
            {{ props.row.delivery.id }}
          </b-button>
        </span>
        <span v-else-if="props.column.field === 'id'">
          <b-button
            :to="{ name: 'OrderSummary', params: { orderId: props.row.id } }"
            variant="primary"
            class="p-1 text-white font-weight-bolder"
          >
            {{ props.row.reference_id }}
          </b-button>
        </span>
        <span v-else-if="props.column.field === 'delivery_date'">
          {{ $helpers.formatDateTime(props.row.delivery_date) }}
        </span>
        <span v-else-if="props.column.field === 'smartCut'">
          {{ $helpers.formatTotal(+props.row.smarttruck_cut + +props.row.pickup_charge) }}
        </span>
        <span v-else-if="props.column.field === 'pickup_photo'">
          <b-button
            v-if="!props.row.has_pickup_photo"
            variant="primary"
            class="font-weight-bolder w-100"
            @click="upload(props.row.id, 'pickup')"
          >
            {{ $t('upload') }}
          </b-button>
          <b-button
            v-else
            variant="success"
            class="font-weight-bolder w-100"
            @click="showImage(props.row.pickup_photo, props.row.id, 'pickup')"
          >
            {{ $t('uploaded') }}
          </b-button>
        </span>
        <span v-else-if="props.column.field === 'delivered_photo'">
          <b-button
            v-if="!props.row.has_delivered_photo"
            variant="primary"
            class="font-weight-bolder w-100"
            @click="upload(props.row.id, 'delivered')"
          >
            {{ $t('upload') }}
          </b-button>
          <b-button
            v-else
            variant="success"
            class="font-weight-bolder w-100"
            @click="showImage(props.row.delivered_photo, props.row.id, 'delivered')"
          >
            {{ $t('uploaded') }}
          </b-button>
        </span>
        <span v-else-if="props.column.field === 'good_return_photo'">
          <b-button
            v-if="!props.row.has_good_return_photo"
            variant="primary"
            class="font-weight-bolder w-100"
            @click="upload(props.row.id, 'grn')"
          >
            {{ $t('upload') }}
          </b-button>
          <b-button
            v-else
            variant="success"
            class="font-weight-bolder w-100"
            @click="showImage(props.row.good_return_photo, props.row.id, 'grn')"
          >
            {{ $t('uploaded') }}
          </b-button>
        </span>
        <span v-else-if="props.column.field === 'status'">
          <b-button
            :variant="props.row.status.color_name"
            class="w-100 font-weight-bolder"
            @click="showStatusModal(props.row)"
          >
            {{ props.row.status.text }}
          </b-button>
        </span>
        <span v-else-if="props.column.field === 'actions'">
          <b-button
            variant="secondary"
            class="font-weight-bolder"
            :to="{ name: 'DeliveryTracking', params: { orderId: props.row.id } }"
          >
            <feather-icon icon="MapPinIcon" />
            {{ $t('track_now') }}
          </b-button>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </MyTable>

    <b-modal
      id="status"
      ref="status"
      :title="$t('delivery_status_update')"
      no-close-on-backdrop
    >
      <b-overlay :show="isLoading">
        <b-form-group v-if="order">
          <VueSelect
            v-model="selected"
            :options="options.filter(i => i.value !== order.status.value)"
          />
        </b-form-group>
      </b-overlay>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="secondary"
          @click="cancel()"
        >
          {{ $t('cancel') }}
        </b-button>
        <b-button
          :disabled="!selected || isLoading"
          size="sm"
          variant="primary"
          @click="updateStatus"
        >
          {{ $t('submit') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store/index'
import axios from '@axios'
import MyTable from '@/components/MyTable.vue'
import { VueSelect } from 'vue-select'
import { mapGetters } from 'vuex'

export default {
  name: 'MyTask',
  components: {
    MyTable,
    VueSelect,
  },
  data() {
    return {
      selected: null,
      selectedOrder: null,
      columns: [
        {
          label: this.$t('delivery_id'),
          field: 'delivery.id',
          type: 'number',
          tdClass: 'text-nowrap align-middle text-left pl-1',
          thClass: 'text-left',
        },
        {
          label: this.$t('order_id'),
          field: 'id',
          type: 'number',
          tdClass: 'text-nowrap align-middle text-left pl-1',
          thClass: 'text-left',
        },
        {
          label: this.$t('senders_name'),
          field: 'sender.name',
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('recipients_name'),
          field: 'recipient.name',
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('delivery_date_time'),
          field: 'delivery_date',
          dateInputFormat: 'dd-MM-yyyy\'T\'HH:mm',
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('total_earn'),
          field: 'smartCut',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('pickup_image'),
          field: 'pickup_photo',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('delivered_image'),
          field: 'delivered_photo',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('good_return_note'),
          field: 'good_return_photo',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('status'),
          field: 'status',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('track'),
          field: 'actions',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
      ],
      rows: [],
      searchTerm: '',
      options: [
        { value: 3, label: 'Arrive at Sender' },
        { value: 4, label: 'Arrive at Golog' },
        { value: 5, label: 'In Transit' },
        { value: 6, label: 'Picked by Lorry' },
        { value: 7, label: 'Out for Delivery' },
        { value: 8, label: 'Delivered' },
        { value: 9, label: 'Request for Cancellation' },
      ],
      reasonOptions: null,
      order: null,
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'orders/checkLoadingState',
      orders: 'orders/checkOrders',
    }),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getOrders()
    this.getReasons()
  },
  methods: {
    async getReasons() {
      const response = await axios.get('reasons?type=1')
      this.reasonOptions = response.data
    },
    getOrders() {
      const query = 'orders_for=smarttruck&filter_by=my_tasks'
      this.$store.dispatch('orders/fetchOrders', { query })
    },
    async upload(id, option) {
      const { value: file } = await this.$swal.fire({
        title: `${this.$t('upload')} ${option} ${this.$t('image')}`,
        input: 'file',
        inputAttributes: {
          accept: 'image/*',
          'aria-label': `${this.$t('upload_your')} ${option} ${this.$t('picture')}`,
        },
        confirmButtonText: this.$t('upload'),
        showCloseButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          input: 'my-2 p-1',
        },
        inputValidator: value => new Promise(resolve => {
          if (!value) {
            resolve(this.$t('choose_an_image'))
          }
          resolve()
        }),
      })

      if (file) {
        const form = new FormData()
        form.append(option, file)
        form.append('orderId', id)
        const response = await axios.post('/uploadDeliveryEvidence', form)
        if (response.data.status) {
          const reader = new FileReader()
          reader.onload = e => {
            this.$swal({
              title: this.$t('your_uploaded_picture'),
              imageUrl: e.target.result,
              imageAlt: this.$t('the_uploaded_picture'),
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
              .then(() => {
                this.getOrders()
              })
          }
          reader.readAsDataURL(file)
        }
      }
    },
    async showImage(url, id, option) {
      this.$swal({
        title: `${this.$t('your_uploaded')} ${option.toUpperCase()} ${this.$t('photo')}`,
        imageUrl: url,
        imageAlt: `${option} ${this.$t('image')}`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-primary mx-1',
        },
        showCancelButton: true,
        cancelButtonText: this.$t('reupload_image'),
        reverseButtons: true,
      })
        .then(result => {
          if (result.dismiss) this.upload(id, option)
        })
    },
    async showStatusModal(order) {
      this.$refs.status.show()
      this.order = order
    },
    async updateStatus() {
      if (this.selected.value === 9) {
        const { value: reason } = await this.$swal.fire({
          title: this.$t('select_a_valid_reason'),
          icon: 'warning',
          input: 'select',
          inputOptions: this.reasonOptions.map(i => i.name),
          inputPlaceholder: this.$t('select_a_reason'),
          showCancelButton: true,
          cancelButtonText: this.$t('cancel'),
          confirmButtonText: this.$t('submit'),
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-secondary mx-1',
            validationMessage: 'my-2',
          },
          inputValidator: value => new Promise(resolve => {
            if (!value) {
              resolve(this.$t('select_a_valid_reason_error'))
            }
            resolve()
          }),
        })

        if (reason) {
          const cause = this.reasonOptions[reason]
          const response = await axios.post('delivery-cancellations', {
            order_id: this.order.id,
            order_status: this.selected.value,
            driver_id: this.order.driver.id,
            reason_id: cause.id,
          })
          if (!response.status === 200) {
            this.$swal({
              title: this.$t('error'),
              text: response.message,
              icon: 'error',
              customClass: {
                confirmButton: 'bg-primary',
              },
            })
            return
          }
          this.$swal({
            title: this.$t('delivery_cancellation_submitted'),
            text: this.$t('delivery_cancellation_info'),
            icon: 'info',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
          this.selected = null
          this.$refs.status.hide()
          this.getOrders()
        }
      } else if (this.selected.value === 8) {
        this.$swal({
          title: this.$t('do_you_want_to_update_status'),
          icon: 'question',
          showCancelButton: false,
          showDenyButton: true,
          confirmButtonText: this.$t('yes_update_status'),
          denyButtonText: this.$t('no_cancel_it'),
          reverseButtons: true,
          customClass: {
            denyButton: 'mx-1 bg-secondary',
            confirmButton: 'bg-success',
          },
        }).then(async result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            await this.setStatus()
            this.$swal({
              title: this.$t('saved'),
              text: this.$t('thank_you_for_your_hardwork'),
              icon: 'success',
              customClass: {
                confirmButton: 'bg-primary',
              },
            })
            this.selected = null
            this.$refs.status.hide()
            this.getOrders()
          } else if (result.isDenied) {
            this.$swal({
              title: this.$t('not_saved'),
              text: this.$t('no_actions_taken'),
              icon: 'info',
              customClass: {
                confirmButton: 'bg-primary',
              },
            })
            this.$refs.status.hide()
            this.selected = null
          }
        })
      } else {
        await this.setStatus()
        this.$swal({
          title: this.$t('success'),
          icon: 'success',
          text: this.$t('status_has_been_updated'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        this.selected = null
        this.$refs.status.hide()
        this.getOrders()
      }
    },
    async setStatus() {
      this.$store.commit('orders/SET_IS_LOADING', true)
      await axios.patch(`order-status/${this.order.id}`, {
        status: this.selected.value,
      })
      this.$store.commit('orders/SET_IS_LOADING', false)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  select.swal2-select {
    width: 100%;
  }
</style>
